<template>
  <div :class="baseClass + '-mobile-top-page'">
    <div :class="baseClass + '-mobile-top-page-title'">Top Games</div>
    <div :class="baseClass + '-mobile-top-page-content'">
      <div :class="baseClass + '-mobile-top-page-top'">
        <div :class="baseClass + '-mobile-top-page-top-title'">
          Most popular
        </div>
        <div :class="baseClass + '-mobile-top-page-top-content'">
          <Game
            v-for="item of $store.state.mobileHot"
            :item="item"
            :key="item.id"
            type="mobile-top"
          />
        </div>
      </div>
    </div>
    <div :class="baseClass + '-mobile-top-page-ads'"></div>
    <AllGames />
    <Tips />
  </div>
</template>
<script>
import Game from "@/components/GameItem.vue";
import AllGames from "@/components/MobileAllGames.vue";
import Tips from "@/components/Tips.vue";
export default {
  components: { Game, AllGames, Tips },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss">
.#{$baseClass}-mobile-top-page {
  padding: 0 15px;
  &-title {
    font-size: 24px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #ffffff;
    padding: 10px 0;
  }
  &-ads {
    width: 100%;
    height: 280px;
    // background: #9b9b9b;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  &-top {
    width: 345px;
    height: 248px;
    background: #006cf3;
    border-radius: 14px;
    position: relative;
    &-content {
      width: 100%;
      height: 100%;
    }
    &-title {
      width: 134px;
      height: 28px;
      background: #ff5f8b;
      border-radius: 14px 6px 8px 0px;
      border: 1px solid #ffffff;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
    }
  }
  &-content {
  }
}
</style>
